<script setup lang="ts"></script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_273_703)">
            <path
                d="M12 24C5.36761 24 0 18.633 0 12C0 5.3677 5.367 0 12 0C18.6324 0 24 5.367 24 12C24 18.6323 18.633 24 12 24ZM13.2323 7.23652C13.2323 6.85739 12.6795 6.47836 12.0002 6.47836C11.2894 6.47836 10.784 6.85739 10.784 7.23652V13.2547C10.784 13.6969 11.2895 13.9971 12.0002 13.9971C12.6795 13.9971 13.2323 13.6969 13.2323 13.2547V7.23652ZM12.0003 15.4502C11.2737 15.4502 10.7051 15.9872 10.7051 16.5875C10.7051 17.1878 11.2737 17.709 12.0003 17.709C12.7111 17.709 13.2798 17.1878 13.2798 16.5875C13.2798 15.9872 12.711 15.4502 12.0003 15.4502Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_273_703">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="matrix(1 0 0 -1 0 24)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
